<template>
  <div class="card">
    <DataView :value="dataviewValue" :layout="layout" :paginator="true" :rows="8"
		paginatorTemplate="RowsPerPageDropdown PrevPageLink FirstPageLink CurrentPageReport LastPageLink NextPageLink"
    :rowsPerPageOptions="[1,8,12,40,100]" currentPageReportTemplate="{currentPage}/{totalPages}ページ"
    paginatorPosition="both">
      <template #header>
        <div class="grid grid-nogutter">
          <DataViewLayoutOptions style="text-align: left" v-model="layout"/>
        </div>
      </template>
      <template #list="slotProps">
        <div class="col-12">
          <div class="card m-1 border-0">
            {{ slotProps.data.news_datetime }} {{ slotProps.data.id }} {{ slotProps.data.news_overview }}
          </div>
        </div>
      </template>
      <template #grid="slotProps">
        <div class="col-12 md:col-3">
          <div class="card m-1 border-0">
            {{ slotProps.data.news_datetime }} {{ slotProps.data.news_overview }}
          </div>
        </div>
      </template>
    </DataView>
  </div>
</template>

<script>
export default {
  props: {
    newsParam: Object,
    dataviewValue: Object,
  },
  data() {
    return {
      layout: 'list', // l(list), g(grid)
    }
  },
  mounted() {
    console.log('NewsList.vue mounted');
    if(!this.dataviewValue){
      this.$emit('refreshNewsData', this.$route.params.code);
    }else{
      console.log('newsData already exists');
    }
    this.$emit('setCurrentTabID', 0);
  },
  methods: {
  }
}
</script>